import React from 'react'

export default function HeaderIcon(props) {
  return (
    <svg version="1.1" x="0" y="0" width={props.width || 1024} height={props.height || 1024} viewBox="0, 0, 1024, 1024">
      <g id="Layer_1">
        <g>
          <g>
            <path d="M182.63,41.152 C190.169,41.152 194.281,43.893 195.652,50.747 L229.235,193.303 C229.92,197.415 233.347,196.045 234.718,191.932 L259.391,53.489 C261.447,44.579 266.245,40.467 274.469,40.467 L322.445,40.467 C331.354,40.467 334.781,44.579 333.411,53.489 L284.75,328.32 C283.379,336.545 278.581,341.342 269.672,341.342 L206.618,341.342 C199.079,341.342 194.967,338.601 193.596,331.747 L160.698,189.191 C159.328,185.079 155.901,186.45 155.215,190.562 L130.542,329.006 C129.172,337.23 124.374,342.028 115.464,342.028 L67.489,342.028 C59.264,342.028 55.152,337.23 56.523,329.006 L105.184,54.174 C107.24,45.264 112.037,41.152 120.262,41.152 L182.63,41.152 z" fill="#FFFFFF"/>
            <path d="M539.706,241.964 C534.908,268.694 516.403,341.342 419.767,341.342 C323.13,341.342 329.984,269.379 334.781,241.964 L352.601,139.845 C357.398,113.116 375.903,40.467 472.54,40.467 C569.177,40.467 561.638,112.43 557.525,139.845 L539.706,241.964 z M482.82,161.776 C488.303,130.25 489.674,111.059 459.518,111.059 C429.362,111.059 423.879,130.25 418.396,161.776 L408.116,220.718 C402.633,252.245 401.262,271.435 431.418,271.435 C461.574,271.435 467.057,252.245 472.54,220.718 L482.82,161.776 z" fill="#FFFFFF"/>
            <path d="M587.681,54.859 C586.996,45.95 592.479,40.467 601.389,40.467 L652.106,40.467 C659.645,40.467 665.128,44.579 664.442,51.433 L656.903,230.313 C657.589,234.425 661.016,234.425 662.386,230.313 L722.698,51.433 C725.44,43.894 730.923,40.467 738.462,40.467 L775.472,40.467 C783.011,40.467 787.808,44.579 787.808,51.433 L785.067,230.313 C785.752,234.425 789.179,234.425 790.55,230.313 L846.75,51.433 C848.806,43.894 854.974,40.467 862.513,40.467 L913.23,40.467 C922.14,40.467 925.567,45.95 922.825,54.859 L822.076,329.691 C819.335,337.23 813.167,340.657 805.628,340.657 L745.316,340.657 C737.776,340.657 733.664,336.545 732.979,329.691 L727.496,224.145 C727.496,220.033 724.069,220.033 722.013,224.145 L680.206,329.691 C677.464,337.23 671.296,340.657 663.757,340.657 L603.445,340.657 C595.906,340.657 591.108,336.545 591.108,329.691 L587.681,54.859 z" fill="#FFFFFF"/>
          </g>
          <path d="M77.769,357.791 C81.196,357.791 82.567,359.162 81.881,362.589 L56.523,466.079 C55.837,468.82 53.781,470.876 51.04,470.876 L33.22,470.876 C30.479,470.876 28.423,468.82 29.108,466.079 L54.467,362.589 C55.152,359.162 57.208,357.791 59.949,357.791 L77.769,357.791 z" fill="#FFFFFF"/>
          <g>
            <path d="M305.311,357.791 C381.386,357.791 406.745,390.689 394.408,460.596 C382.072,531.189 345.747,563.401 268.986,563.401 L248.425,563.401 C240.201,563.401 234.718,567.513 233.347,576.423 L221.011,644.959 C219.64,653.869 214.842,657.981 205.933,657.981 L157.957,657.981 C149.733,657.981 145.62,653.184 146.991,644.959 L195.652,370.813 C197.023,361.903 201.82,357.791 210.73,357.791 L305.311,357.791 z M284.75,490.067 C305.311,490.067 316.276,487.325 321.074,460.596 C325.872,433.867 315.591,431.125 295.03,431.125 L271.728,431.125 C263.503,431.125 258.02,435.237 256.65,444.147 L250.481,477.045 C249.111,485.269 253.223,490.067 261.447,490.067 L284.75,490.067 z" fill="#FFFFFF"/>
            <path d="M608.928,559.289 C604.13,586.018 585.625,658.667 488.989,658.667 C392.352,658.667 399.206,586.703 404.003,559.289 L421.823,457.169 C426.62,430.44 445.125,357.791 541.762,357.791 C638.398,357.791 630.859,429.754 626.747,457.169 L608.928,559.289 z M552.728,478.415 C558.211,446.889 559.581,427.698 529.425,427.698 C499.269,427.698 493.786,446.889 488.303,478.415 L478.023,537.357 C472.54,568.884 471.169,588.074 501.325,588.074 C531.481,588.074 536.964,568.884 542.447,537.357 L552.728,478.415 z" fill="#FFFFFF"/>
            <path d="M658.959,372.184 C658.274,363.274 663.757,357.791 672.667,357.791 L723.384,357.791 C730.923,357.791 736.406,361.903 735.72,368.757 L728.181,547.637 C728.867,551.75 732.294,551.75 733.664,547.637 L793.977,368.757 C796.718,361.218 802.201,357.791 809.74,357.791 L846.75,357.791 C854.289,357.791 859.086,361.903 859.086,368.757 L856.345,547.637 C857.03,551.75 860.457,551.75 861.828,547.637 L918.028,368.757 C920.084,361.218 926.252,357.791 933.791,357.791 L984.508,357.791 C993.418,357.791 996.845,363.274 994.103,372.184 L892.669,647.016 C889.928,654.555 883.759,657.981 876.22,657.981 L815.908,657.981 C808.369,657.981 804.257,653.869 803.572,647.016 L798.089,541.469 C798.089,537.357 794.662,537.357 792.606,541.469 L750.798,647.016 C748.057,654.555 741.889,657.981 734.35,657.981 L674.038,657.981 C666.498,657.981 661.701,653.869 661.701,647.016 L658.959,372.184 z" fill="#FFFFFF"/>
          </g>
          <path d="M146.991,401.654 C143.564,401.654 142.879,400.284 142.879,396.857 C143.564,390.689 140.137,385.891 132.598,384.52 C126.43,383.835 121.632,386.576 120.947,390.003 C119.576,402.34 168.923,408.508 157.957,443.462 C156.586,448.259 142.879,474.303 105.869,469.506 C72.971,465.393 72.971,443.462 75.713,431.125 C76.398,428.384 78.454,427.013 81.196,426.328 L100.386,424.957 C103.813,424.957 105.184,426.328 104.498,429.754 C103.127,437.293 107.24,442.091 114.779,443.462 C122.318,444.147 129.171,440.72 129.857,436.608 C131.227,423.586 83.252,420.159 94.218,385.206 C95.588,380.408 106.554,353.679 141.508,358.476 C173.72,362.589 175.091,383.835 172.349,395.486 C171.664,398.228 169.608,399.598 166.867,400.284 L146.991,401.654 z" fill="#FFFFFF"/>
        </g>
      </g>
    </svg>
  )
}